<template>
	<div>
		<!-- Toolbar -->
		<n-toolbar title="分類" left-text="返回" right-text="訂單" @clickLeft="$router.go(-1)" @clickRight="$router.push('/adjustment')" />
		<!-- Content -->
		<div class="bg-light-blue-gray h-screen overflow-auto">
			<!-- Header -->
			<div class="sticky top-0 z-10 w-full bg-light-blue-gray p-2 flex justify-center text-xl font-bold text-secondary">{{ catalogItem(id).category.name }}</div>
			<!-- Product list -->
			<div class="flex flex-wrap px-3 pb-20">
				<div v-for="productId in catalogItem(id).category.productIds" :key="productId" class="w-1/3 h-24 text-md p-1 relative mt-2">
					<n-badge v-if="currentAdjustment.products.find(product => product.id === productId)" color="danger"></n-badge>
					<n-button class="active:bg-primary active:text-white" color="light" @click="$router.push(`/product/${productId}`)">{{ catalogItem(productId).product.name }}</n-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			id: this.$route.params.id,
		}
	},

	computed: {
		...mapGetters(['currentShop', 'currentAdjustment', 'catalog', 'catalogItem']),
	},
}
</script>
